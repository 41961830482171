import React from "react";
import MainBannerScreen from "../../../images/bannerCover.png";
import floraPodIcon from "../../../images/icons/floraPodIcon.svg";
// import Logo from '../../../images/icons/newLogo.png';
import { StaticImage } from "gatsby-plugin-image";
import { Link, navigate } from "gatsby";
import DownloadButtons from "../../UI/DownloadButtons";
import { SOCIAL } from ".";
import Features from "../Features";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Button from "../../UI/Button";
import { BUY_FLORA } from "../../../assets/Links";
const DesktopView = () => {
  return (
    <div
      className="hidden md:flex w-[100%]  flex-col justify-center items-center bg-no-repeat overflow-hidden  bg-cover md:px-5 lg:px-20 pb-10 xl:self-center"
      style={{ backgroundImage: `url('${MainBannerScreen}')` }}
    >
      <div className="w-full xl:w-[1200px]">
        <section className="flex justify-between mt-40 mb-24">
          <aside className="w-[44%] flex flex-col justify-center md:py-0 lg:pb-8 ">
            {/* <Link to="/" className="flex items-center top-2 w-full">
            <img src={Logo} className="mr-1.5 h-7" alt="Flora Logo" />
            <span className="relative md:text-xl font-bold whitespace-nowrap   lg:text-3xl">
              FLORA
            </span>
          </Link> */}
            <h1
              role="heading"
              className="font-bold text-avenir800 md:text-3xl lg:text-6xl lg:leading-tight"
            >
              Elevate Your Plant Game with Flora
            </h1>
            <h2
              role="heading"
              className="text-avenir500 md:text-xl lg:text-3xl mt-4"
            >
              Discover expert plant care at your fingertips: Watering reminders,
              plant identification, and a global community of plant parents
            </h2>
            <a href={BUY_FLORA}>
              <Button
                frontIcon={floraPodIcon}
                label="Shop Now"
                className="text-xl text-white px-5 py-3 mt-7 w-[60%]"
              />
            </a>
            {/* <DownloadButtons
              className="md:h-8 lg:h-14 md:w-2/3 lg:w-96 justify-start mt-8"
              appStoreClass="h-full w-full ml-5 rounded-xl"
              playStoreClass="h-full w-full rounded-xl"
            /> */}
            <div className="flex w-2/5 justify-between mt-6">
              {SOCIAL?.map((item) => {
                return (
                  <LazyLoadImage
                    role="button"
                    src={item?.src}
                    alt={item.link}
                    className="p-2 h-10 lg:h-12 cursor-pointer"
                    onClick={() => navigate(item.link)}
                  />
                );
              })}
            </div>
          </aside>
          <aside className="w-3/5  ml-5 lg:-mr-10">
            <StaticImage
              className="h-100vh "
              src="../../../images/heroBanner.webp"
            />
          </aside>
        </section>
        <Features />
      </div>
    </div>
  );
};
export default DesktopView;
