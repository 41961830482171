import React, { useState } from 'react';
import Logo from '../../../images/icons/newLogo.svg';
import YellowStar from '../../../images/icons/YellowStar.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CarouselCard2 from '../../UI/CarouselCard2';
import { DOWNLOAD } from '../../../assets/Links';
import { navigate } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const progressData = [
  {
    id: 1,
    width: '95%',
    backgroundColor: '#00C9A4',
  },
  {
    id: 2,
    width: '25%',
    backgroundColor: '#00C9A4',
  },
  {
    id: 3,
    width: '15%',
    backgroundColor: '#00C9A4',
  },
  {
    id: 4,
    width: '10%',
    backgroundColor: '#00C9A4',
  },
  {
    id: 5,
    width: '5%',
    backgroundColor: '#00C9A4',
  },
];
export const data = [
  {
    id: 1,
    name: 'Trisan Sapple',
    title: 'This app is amazing!',
    star: 5,
    description: `“Perfect for all levels of plant care. Whether you are a beginner, intermediate, or expert, this app helps you keep track of your plants' health.“`,
  },
  {
    id: 2,
    title: 'Love this App!',
    name: 'Mary Robinson',
    star: 5,
    description:
      '“This app is a lifesaver for me. I was never able to keep any plant alive before. Flora gives me the knowledge and reminders to keep 15 plants alive for over a year now.“',
  },
  {
    id: 3,
    title: `It's a great app!`,
    name: 'Mel Raplee',
    star: 5,
    description:
      '“It reminds me to water my plants and customizes the options based on different factors like room temp, direction of the window, and type of soil and plant pot“',
  },
];
const Ratings = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (current, next) => setSlideIndex(next),
    // appendDots: (dots) => (
    //   <div>
    //     <ul style={{ margin: "0px" }}> {dots} </ul>
    //   </div>
    // ),
    // customPaging: (current, next) => (
    //   <div className={current === slideIndex ? "dot dot-active" : "dot"}></div>
    // ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="py-10  px-5 sm:px-6 md:max-lg:px-0 xl:px-40 md:px-8 mx-auto md:flex flex-col items-center">
      <div className="w-full xl:w-[1200px]">
        <section className="grid grid-cols-4 grid-flow-row gap-4 md:grid-cols-6 md:grid-rows-3 ">
          <div className="py-2 md:row-span-3">
            <div
              role="button"
              tabIndex="0"
              className="bg-[#F9FCFE] rounded-2xl w-full p-2 shadow-lg hover:shadow-xl md:p-6 cursor-pointer"
            >
              <LazyLoadImage
                tabIndex="0"
                aria-label="flora_logo"
                role="button"
                src={Logo}
                className="h-full w-full"
                alt="Flora_Logo"
                onClick={() => navigate(DOWNLOAD)}
              />
            </div>
          </div>
          <div className="col-span-3 md:col-span-5 md:col-start-2">
            <p role="heading" className="font-avenir800 text-2xl font-bold md:text-3xl">
              Flora - Plant Care and Diagnosis
            </p>
            <p role="heading" className="font-avenir400 text-base md:text-xl">
              Watering Schedules & Plant Identification
            </p>
          </div>
          <div className="flex flex-col justify-between col-span-2 md:col-span-2 lg:col-span-1  md:row-span-2">
            <div className="flex items-baseline">
              <p
                role="heading"
                className="font-semibold text-2xl font-avenir800 mr-1.5 md:text-3xl"
              >
                4.7{' '}
              </p>
              <p role="heading" className="text-lg font-avenir500 md:text-xl">
                out of 5
              </p>
            </div>
            <div className="flex justify-between">
              {[1, 2, 3, 4, 5]?.map((star, index) => (
                <img alt="rating" src={YellowStar} className="h-6 md:h-8" key={index} />
              ))}
            </div>
            <p role="heading" className="text-base font-avenir500 md:text-xl">
              1.5K Ratings
            </p>
          </div>
          <div className="flex flex-col justify-between col-span-2 md:col-span-3  md:row-span-2 lg:col-span-4 ">
            {progressData?.map((progress) => {
              return (
                <div className="w-full text-center mb-2" key={progress.id}>
                  <div className="mb-1 w-full h-7.5 bg-lightGray/30 rounded-full">
                    <div
                      className="h-full text-center text-xs font-avenir900 text-white rounded-full py-1.5"
                      style={{
                        width: progress.width,
                        backgroundColor: progress.backgroundColor,
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </section>
        <section>
          <Slider {...settings} className="flex flex-row justify-between">
            {data?.map((story, index) => (
              <CarouselCard2
                className={index === slideIndex ? 'slide slide-active' : 'slide'}
                key={index}
                {...story}
              />
            ))}
          </Slider>
        </section>
      </div>
    </div>
  );
};

export default Ratings;
