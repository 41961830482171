import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import { data } from "./Data";
import CarouselCard from "../../UI/CarouselCard";
import React from "react";

const Stories = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (current, next) => setSlideIndex(next),
    // appendDots: (dots) => (
    //   <div>
    //     <ul style={{ margin: "0px" }}> {dots} </ul>
    //   </div>
    // ),
    // customPaging: (current, next) => (
    //   <div className={current === slideIndex ? "dot dot-active" : "dot"}></div>
    // ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="bg-gradient-to-b from-[#d1fae4] to-[#F1FFF7]  py-8 md:py-12  md:px-0 xl:px-0 md:flex flex-col justify-center items-center">
      <div className="w-full xl:w-[1200px]">
        <h2
          className="text-avenir800 ml-2 mb-5 text-2xl font-bold text-center md:text-[32px]"
          data-sal="fade"
          data-sal-duration="100"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          What our plant parents are saying:
        </h2>
        <Slider {...settings} className="flex flex-row justify-between mx-7 ">
          {data?.map((story, index) => (
            <CarouselCard
              className={`w-screen ${
                index === slideIndex ? "slide-current" : "slide"
              }`}
              key={index}
              {...story}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Stories;
