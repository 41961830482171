import storyprofile1 from "../../../images/StoryProfile1.png";
import storyprofile2 from "../../../images/StoryProfile2.png";
import storyprofile3 from "../../../images/StoryProfile3.png";

export const data = [
  {
    id: 1,
    img: storyprofile1,
    title: "Favourite plant app!",
    star: 5,
    description:
      "“I have been using this app for I think around a year now and it has only gotten better! I love the tips for plants that it gives and the ability to add custom plants has always been very useful for me ! With each update the app gets smoother and better and I can’t wait to see what comes next! They’re constantly improving and the fact that the app maintains a minimal design while still feeling alive is a giant plus! Great job on a fantastic app!”",
  },
  {
    id: 2,
    title: "Use it every day for over a year",
    img: storyprofile2,
    star: 5,
    description:
      "“I use this app every day. Over the course of the pandemic, I have acquired a lot of plants. This app has made it very easy for me to monitor my plants needs without reviewing every plant and it’s soil every single day. This app allows me to focus in on a select few that need attention throughout the week, making having a lot of plants more manageable. I’ve been able to reach out to support and make suggestions which has come with positive response and feature upgrades that make the app even better than it was when I downloaded it a year ago. The app is always striving to do better and they add features that new and veteran plant parents both benefit from. I recommend this app to everyone!”",
  },
  {
    id: 3,
    title: "Holy Wow",
    img: storyprofile3,
    star: 5,
    description:
      "“This app is everything you could want in a plant app and more!! The team dedicated to building flora is incredible and have put so much time and effort into even the smallest details and it seriously shows. Everything is so simplified and easy to understand even for plants that are difficult to care for. Next to those friendly little reminders to water my plants, the community garden might be my favorite aspect to the app because the only thing better than being a plant mom is having OTHER plant parents to obsess over plants with. Super awesome app, definitely get it!”",
  },
];
