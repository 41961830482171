import React from "react";
import YellowStar from "../../../images/icons/YellowStar.svg";
// import DoubleQuote from "../../../images/DoubleQuote.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CarouselCard2 = ({ name, title, description, star }) => {
  const starArray = Array.from({ length: star }, (_, index) => index + 1);

  return (
    <>
      <div className="rounded-xl min-h-80 h-full bg-[#EEF4F8] p-5 my-5  md:my-10 mx-2 md:mx-4 md:p-6">
        <div className="flex">
          {starArray?.map((star, index) => (
            <LazyLoadImage
              alt="rating"
              src={YellowStar}
              className="h-8 mr-1"
              key={index}
            />
          ))}
        </div>
        <p className="text-avenir400  text-base my-2">{name}</p>
        <p className="text-avenir800 font-bold text-lg">{title}</p>
        <div>
          <p className=" leading-8 text-lg text-avenir500  md:text-justify">
            {description}
          </p>
        </div>
      </div>
    </>
  );
};

export default CarouselCard2;
