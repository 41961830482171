import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Button = ({
  label,
  handleClick,
  frontIcon,
  backIcon,
  className = "",
}) => (
  <button
    className={`font-avenir900 bg-gradient-to-br from-[#78dd4a] to-[#38c7be] rounded-full hover:bg-gradient-to-bl  ${className}`}
    onClick={handleClick}
  >
    <div className="flex justify-center items-center">
      {frontIcon && (
        <LazyLoadImage
          src={frontIcon}
          className="mr-2 mb-1 h-[24px] w-[24px]"
        />
      )}
      {label}
      {backIcon && (
        <LazyLoadImage src={backIcon} className="ml-2 mb-1 h-[24px] w-[24px]" />
      )}
    </div>
  </button>
);

export default Button;
