import React from "react";
// import Features from "./Features";
// import MainBanner from "./MainBanner";
import Stories from "./Stories";
import BlogHomeFooter from "./BlogFooter";
// import Gift from "./Gift";
import MorePlants from "./MorePlants";
import DownloadAppBar from "./DownloadAppBar";
import DownloadAppBarSticky from "../Layout/DownloadAppBar";
import FooterBanner from "./FooterBanner";
import Ratings from "./Ratings";
import FloraWork from "./FloraWork";
import NewMainBanner from "./NewMainBanner";
import HomeScreenPopup from "./HomeScreenPopup";

const Home = (props) => {
  return (
    <div>
      <DownloadAppBar className="md:hidden" />
      <NewMainBanner />
      {/* <Features />   */}
      <Stories />
      <MorePlants />
      <FloraWork />
      {/* <Gift /> */}
      <Ratings />
      <BlogHomeFooter />
      <FooterBanner />
      <HomeScreenPopup />
      <div className="fixed w-full z-10 " style={{ bottom: "12px" }}>
        <DownloadAppBarSticky className="my-0 mx-auto" />
      </div>
    </div>
  );
};

export default Home;
