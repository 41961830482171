import React from 'react';
import Feature1 from '../../../images/Feature1.svg';
import Feature2 from '../../../images/Feature2.svg';
import Feature3 from '../../../images/Feature3.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const FEATURES = [
  {
    title: 'Identify, care, and grow',
    img: Feature1,
    desc: 'Flora can identify over 10,000 plants, and give you care guides and reminders so that your plants can thrive - all for free.',
  },
  {
    title: 'A blooming community',
    img: Feature2,
    desc: 'Join over 200,000 plant parents all sharing their growth journeys.',
  },
  {
    title: 'Get rewarded for your green thumbs',
    img: Feature3,
    desc: 'Earn seed points and badges through caring for your plants, which you can then spend on discounts, giveaways, and several rewards!',
  },
];

const Features = () => {
  return (
    <main className="bg-[#F1FFF7] -mt-36 pt-36 md:bg-transparent md:pt-10 lg:pt-40">
      <h2
        className="text-avenir800 px-5 mt-6  mb-3 text-2xl md:text-3xl md:mb-4  font-bold text-center lg:text-4xl"
        data-sal="fade"
        data-sal-duration="200"
        data-sal-delay="100"
        data-sal-easing="ease"
      >
        Take the guesswork out of plant care!
      </h2>
      <div className="md:grid grid-cols-3  grid-flow-col gap-4 md:pt-12">
        {FEATURES.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-col p-4"
              data-sal="fade"
              data-sal-duration="100"
              data-sal-delay="10"
              data-sal-easing="ease"
            >
              <div className="justify-center flex flex-col  items-center pb-4  md:flex-row">
                <LazyLoadImage
                  src={item.img}
                  alt="feature1"
                  className="w-24 lg:w-32 rounded-full hover:shadow-lg"
                />
                <p  role="heading" className="text-avenir500 text-lg mt-4 leading-6 font-bold md:px-5 lg:px-10 lg:leading-8 lg:text-xl">
                  {item.title}
                </p>
              </div>
              <div>
                <p className="text-center md:text-base  lg:p-2 leading-7	">{item.desc}</p>
              </div>
            </div>
          );
        })}
      </div>
    </main>
  );
};

export default Features;
