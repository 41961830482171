import React from "react";
import DoubleQuote from "../../../images/DoubleQuote.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CarouselCard = ({ img, title, description, star }) => {
  // const starArray = Array.from({ length: star }, (_, index) => index + 1);

  return (
    <>
      <div className="rounded-2xl min-h-[500px] bg-white shadow-[0px_0px_6px_rgba(0,0,0,0.1)] p-3 my-3 md:my-10 mx-2 md:mx-4 md:p-6">
        <div className="h-full md:h-[48rem] lg:h-[46rem] xl:h-[37rem]">
          <div className="flex flex-row justify-start w-full mb-4">
            <LazyLoadImage
              src={img}
              alt="Avatar"
              className="w-20 h-20 md:w-28 md:h-28 bg-gradient-to-l p-[5px] from-[#2AC2D4] to-[#87E132] rounded-3xl "
            />
            <div className="flex flex-col justify-between items-start my-1 ml-1.5 md:ml-5">
              <div className="py-1">
                <LazyLoadImage
                  src={DoubleQuote}
                  alt="DoubleQuote"
                  className="h-6 md:h-10"
                />
              </div>
              <p className="text-avenir800 font-bold text-base whitespace-pre-wrap md:text-lg">
                {title}
              </p>
              {/* <div className="w-4/5 flex flex-row justify-start">
                {starArray?.map((star, index) => (
                  <img
                    alt="rating"
                    src={YellowStar}
                    className="h-6"
                    key={index}
                  />
                ))}
              </div> */}
            </div>
          </div>

          <div>
            <p className="text-sm md:text-avenir500 leading-5 text-justify lg:text-base md:leading-8 overflow-hidden md:text-ellipsis">
              {description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselCard;
