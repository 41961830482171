import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import RightArrow from '../../../images/icons/right.svg';
const MorePlants = () => {
  const data = useStaticQuery(graphql`
    {
      allPlants(skip: 0, limit: 5) {
        edges {
          node {
            id
            plant_types
            imageURL
            scientific_name
            common_name
            slug
          }
        }
      }
    }
  `);

  const plants = data?.allPlants?.edges;
  return (
    <section className="py-10 bg-[#F3FFF9]  px-5 sm:px-6 md:max-lg:px-0 xl:px-40 md:px-8 mx-auto md:flex flex-col justify-center items-center">
      <div className="w-full xl:w-[1200px]">
        <h2
          data-sal="fade"
          data-sal-duration="100"
          data-sal-delay="10"
          data-sal-easing="ease"
          className="flex justify-center text-2xl font-avenir800  mb-5 md:text-4xl  md:flex  md:pt-10 md:pb-5"
        >
          Discover plants with Flora!
        </h2>

        <div
          data-sal="fade"
          data-sal-duration="100"
          data-sal-delay="10"
          data-sal-easing="ease"
          className="w-full grid grid-flow-col gap-x-4 flex-nowrap overflow-scroll  md:overflow-hidden  pb-3 sm:mx-5"
        >
          {plants?.map(({ node }, index) => (
            <Link
              key={index}
              to={`/explore-plants/${node?.slug}`}
              className="md:mr-3 sm:mb-2  sm:ml-0"
            >
              <LazyLoadImage
                loading="lazy"
                className="lazyload  h-40 w-40 object-cover rounded-3xl hover:shadow-lg"
                alt="Plant care for Aloe Vera on Greg, the plant care app"
                src={node?.imageURL}
              />
              <p className="text-avenir900 text-xl w-40 pt-3 font-bold">{node?.common_name}</p>
              <p className="text-avenir900 text-[#A0ACB7] text-lg pt-1 font-medium">
                {node?.plant_types}
              </p>
              <p className="text-avenir900 text-[#A0ACB7] w-40 text-sm pt-1font-medium">
                {node?.scientific_name}
              </p>
            </Link>
          ))}
          <Link
            className="flex flex-col justify-center items-center w-40 cursor-pointer"
            to={`/explore-plants/`}
          >
          <LazyLoadImage src={RightArrow} classname="h-9" alt="flora_Pod" />
            <p className="font-bold text-avenir800 text-center text-3xl">Discover other plants</p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default MorePlants;
