import { navigate } from "gatsby";
import React from "react";
import { BUY_FLORA, DOWNLOAD, FLORA_SHOPIFY } from "../../../assets/Links";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FooterBannerDesktop from "../../../images/home/footerBannerDesktop.webp";
import FooterBannerMobile from "../../../images/home/footerBannerMobile.webp";

const FooterBanner = () => {
  return (
    <div className="py-10 cursor-pointer  px-5 sm:px-6 md:max-lg:px-0 xl:px-40 md:px-8 mx-auto md:flex flex-col items-center">
      <div className="hidden md:block w-full xl:w-[1200px]">
        <LazyLoadImage
          tabIndex="0"
          aria-label="flora_banner"
          role="button"
          src={FooterBannerDesktop}
          alt="flora banner"
          onClick={() => navigate(BUY_FLORA)}
        />
      </div>
      <div className="md:hidden w-full xl:w-[1200px]">
        <LazyLoadImage
          tabIndex="0"
          aria-label="flora_banner"
          role="button"
          src={FooterBannerMobile}
          alt="flora banner"
          onClick={() => navigate(FLORA_SHOPIFY)}
        />
      </div>
    </div>
  );
};

export default FooterBanner;
