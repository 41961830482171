import React from "react";
import FloraWork1 from "../../../images/FloraWork1.webp";
import FloraWork2 from "../../../images/FloraWork2.webp";
import FloraWork3 from "../../../images/FloraWork3.webp";
import FloraWork4 from "../../../images/FloraWork4.webp";
import FloraWork5 from "../../../images/FloraWork5.webp";
import FloraWork6 from "../../../images/FloraWork6.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

const FloraWorkData = [
  {
    image: FloraWork1,
    desc: "1. Download the Flora App (now live in both Android and iOS) and sign in to your garden!",
  },
  {
    image: FloraWork2,
    desc: " 2. Scan your plant and Flora will identify it! Or find your plant in our database (if we haven’t it - add your custom).",
  },
  {
    image: FloraWork3,
    desc: "3. Fill all plant info and get your personalized plant care recommendations!",
  },
  {
    image: FloraWork4,
    desc: "4. Add more plants and create your own paradise garden!",
  },
  {
    image: FloraWork5,
    desc: "5. Join our Community Garden with over 100k+ plant lovers!",
  },
  {
    image: FloraWork6,
    desc: "6. Collect different poins and badges and win special rewards!",
  },
];

const FloraWork = () => {
  return (
    <div className="py-14 px-4 md:px-5 md:py-20 xl:px-40 md:flex flex-col justify-center items-center">
       <div className="w-full xl:w-[1200px]">
      <p role="heading" className="text-avenir900 font-bold text-center pb-12 text-2xl md:text-4xl">
        How does Flora work?
      </p>
      <div className="grid grid-cols-2 gap-x-4  gap-y-6 md:grid-cols-3">
        {FloraWorkData?.map((work, index) => {
          return (
            <div
              key={index}
              data-sal="fade"
              data-sal-duration="1000"
              data-sal-delay="10"
              data-sal-easing="ease"
            >
              <LazyLoadImage
                src={work?.image}
                alt={work?.desc}
                className="md:h-48 roounded-xl hover:shadow-lg"
              />
              <p role="heading" className="text-avenir900 md:text-xl mt-3 text-center">{work?.desc}</p>
            </div>
          );
        })}
      </div>
      </div>
    </div>
  );
};

export default FloraWork;
