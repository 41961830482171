import React from "react";
import Features from "../Features";
import DownloadButtons from "../../UI/DownloadButtons";
import { StaticImage } from "gatsby-plugin-image";
import MainBannerScreenMV from "../../../images/bannerCoverMV.png";
import { SOCIAL } from ".";
import { navigate } from "gatsby";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Button from "../../UI/Button";
import { BUY_FLORA } from "../../../assets/Links";
import floraPodIcon from "../../../images/icons/floraPodIcon.svg";

const MoblieView = () => {
  return (
    <div
      className={`md:hidden w-full h-100vh bg-no-repeat overflow-hidden bg-contain `}
      style={{ backgroundImage: `url('${MainBannerScreenMV}')` }}
    >
      <section
        className={`flex flex-col justify-between items-center pt-4 px-5`}
      >
        <h1 className="font-bold text-avenir800 text-4xl text-center py-3">
          Elevate Your Plant Game with Flora
        </h1>
        <h2 className="text-avenir500 text-lg text-center py-3">
          Discover expert plant care at your fingertips: Watering reminders,
          plant identification, and a global community of plant parents
        </h2>
        <aside className="">
          <StaticImage
            className="h-100vh -mr-5"
            src="../../../images/heroBanner.webp"
          />
        </aside>
        <a href={BUY_FLORA}>
          <Button
            frontIcon={floraPodIcon}
            label="Shop Now"
            className="text-xl text-white px-5 py-3 mt-7 w-[250px]"
          />
        </a>
        {/* <DownloadButtons
          className="h-12 w-4/5 sm:3/5 sm:h-16  my-5"
          appStoreClass="h-full w-full ml-5 rounded-xl"
          playStoreClass="h-full w-full rounded-xl"
        /> */}
        <div className="flex w-3/5 my-5 justify-between">
          {SOCIAL?.map((item) => {
            return (
              <LazyLoadComponent
                role="button"
                src={item?.src}
                alt={item.link}
                className="p-2 h-12 cursor-pointer"
                onClick={() => navigate(item.link)}
              />
            );
          })}
        </div>
      </section>
      <Features />
    </div>
  );
};

export default MoblieView;
