import React from 'react';
import { SOCIAL_LINKS } from '../../../assets/Links';
import Instagram from '../../../images/icons/instagram.png';
// import Discord from "../../../images/icons/discord.png";
import Facebook from '../../../images/icons/facebook.png';
import Tiktok from '../../../images/icons/tiktok.png';
import MoblieView from './MoblieView';
import DesktopView from './DesktopView';
export const SOCIAL = [
  {
    src: Facebook,
    link: SOCIAL_LINKS.FACEBOOK,
  },
  {
    src: Tiktok,
    link: SOCIAL_LINKS.TIKTOK,
  },
  {
    src: Instagram,
    link: SOCIAL_LINKS.INSTA,
  },
];
const NewMainBanner = () => {
  return (
    <div>
      <DesktopView />
      <MoblieView />
    </div>
  );
};

export default NewMainBanner;
