import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react";
import { BUY_FLORA } from "../../../assets/Links";
import closeIcon from "../../../images/icons/cross.svg";

export default function HomeScreenPopup() {
  const [showModal, setShowModal] = useState(false);
  const linkRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, 3000);
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  const onImageClick = (e) => {
    e?.stopProgagation?.();
    linkRef.current?.click?.();
  };

  const onCloseIconClick = (e) => {
    e?.stopProgagation?.();
    setShowModal(false);
  };

  return (
    <>
      {showModal ? (
        <>
          <div
            onClick={closeModal}
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <a href={BUY_FLORA} target="_blank" ref={linkRef} hidden>
              .
            </a>
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <img
                  onClick={onCloseIconClick}
                  src={closeIcon}
                  className="absolute h-4 lg:h-6 w-4 lg:w-6 right-3 top-3 cursor-pointer"
                  style={{ zIndex: "1" }}
                />
                <div className=" p-2 flex-auto" onClick={onImageClick}>
                  <StaticImage
                    className="cursor-pointer"
                    src={"../../../images/home/sharkTankPopup.webp"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
