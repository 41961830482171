import React from "react";
import VerticalLogo from "../../../images/logos/vertical-logo.svg";
import { GET_APP } from "../../../assets/Links";
import { navigate } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SHOW_ANNOUCEMENT_BANNER } from "../../../utils/constant";

const DownloadAppBar = () => {
  return (
    <nav
      className={` flex justify-between items-center px-[18px]  py-[7px] w-full h-[100px] shadow-[0_2px_6px_-3px_rgba(0,0,0,0.3)] md:hidden md:w-100 ${
        SHOW_ANNOUCEMENT_BANNER ? "mt-24" : "mt-14"
      } md:mt-20  bg-white md:px-5 xl:px-40`}
    >
      <div className="flex py-[7px] sm:flex  items-center h-20">
        <LazyLoadImage
          src={VerticalLogo}
          className="mr-0.5  h-9 sm:h-10"
          alt="Flora Logo"
        />
        <div className=" leading-[0.7rem]   md:leading-[1.5rem] ml-3">
          <span className="text-[13px]  font-avenir400 md:text-[18px] lg:text-[24px] md:font-avenir900 ">
            Identify and care for your plants -<br /> Download Flora for Free!
            <br className="md:hidden" />
          </span>
        </div>
      </div>

      <div
        className="flex  justify-between  md:block md:w-auto"
        id="navbar-default"
      >
        <button
          onClick={() => navigate(GET_APP)}
          type="button"
          className="ml-3 w-[102px]  h-[38px]  text-white  justify-center items-center font-avenir900 px-[21px]   text-[16px] bg-gradient-to-br from-[#78dd4a] to-[#38c7be]  rounded-full hover:bg-gradient-to-bl  md:hidden"
        >
          Get app
        </button>
      </div>
    </nav>
  );
};

export default DownloadAppBar;
